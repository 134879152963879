<template>

  <v-menu
      :value="value"
      :close-on-content-click="false"
      :activator="selectedElement"
      :offset-y="true"
  >
    <v-card
        min-width="350px"
        max-width="700px"
    >
      <v-toolbar
          dense
          flat
      >

        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn v-if="!editing" icon small class="ml-2" @click="editing = true">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-dialog v-model="deleting" width="360">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small class="ml-2" v-bind="attrs"
                   v-on="on">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title color="error">{{ $t("actions.deleting") }}</v-card-title>
            <v-card-text>{{ $t("widgets.agenda.fields.confirmDelete") }}</v-card-text>
            <v-card-actions>
              <v-btn text @click="deleting = false">{{ $t("actions.back") }}</v-btn>
              <v-btn color="error" @click="deleteEvent">{{ $t("actions.delete") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn icon small class="ml-2" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-list dense>

          <template v-if="!editing">
            <v-list-item>
              <v-list-item-icon>
                <color :color="event.color || 'primary'" size="20"></color>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ event.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ formatDate(event.start, "LLLL") }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>
                  mdi-text
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title :class="{'font-italic': !event.text}">{{ event.text || $t("widgets.agenda.fields.noDescription") }}</v-list-item-title>
            </v-list-item>

          </template>


          <template v-if="editing">
            <v-list>

              <v-list-item>
                <v-list-item-content>
                  <v-text-field v-model="event.name" :label="$t('widgets.agenda.fields.title')"></v-text-field>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  {{event.start}}
                  <v-datetime-picker v-model="event.start" :label="$t('widgets.agenda.fields.startDate')"
                                     :timePickerProps="{format :'24hr'}"
                                     :datePickerProps="{locale: locale, 'first-day-of-week': '1', allowedDates: allowedDatesStart}"
                                     :dateFormat="getLocaleFormat($i18n.locale)"
                                     :ok-text="$t('app.datetimePicker.okText')"
                                     :clear-text="$t('app.datetimePicker.clearText')"
                                     :key="cheatKey+'start'"
                  ></v-datetime-picker>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-datetime-picker v-model="event.end" :label="$t('widgets.agenda.fields.endDate')"
                                     :timePickerProps="{format: '24hr'}"
                                     :datePickerProps="{locale: locale, 'first-day-of-week': '1', allowedDates: allowedDatesEnd}"
                                     :dateFormat="getLocaleFormat($i18n.locale)"
                                     :ok-text="$t('app.datetimePicker.okText')"
                                     :clear-text="$t('app.datetimePicker.clearText')"
                                     :key="cheatKey+'end'"
                  ></v-datetime-picker>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-textarea auto-grow v-model="event.text" rows="3" :label="$t('widgets.agenda.fields.addDescription')"></v-textarea>
                </v-list-item-content>
              </v-list-item>

              <color-picker v-model="event.color"></color-picker>

            </v-list>

            <v-card-actions v-if="editing">
              {{ $t('app.colorPicker.title')}}
              <v-btn small color="success" class=" ml-2" @click="editEvent">
                <v-icon color="">mdi-content-save</v-icon>
              </v-btn>
            </v-card-actions>

          </template>

        </v-list>
      </v-card-text>

    </v-card>

  </v-menu>
</template>

<script>

import moment from "moment"
import fr from "moment/locale/fr"

export default {
  name: "ViewEvent",
  props: {
    selectedEvent: Object,
    selectedElement: [HTMLDivElement, HTMLElement],
    value: Boolean
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
    allowedDatesStart() {
      if (!this.newEventEnd) {
        return null;
      } else {
        return date => {
          let endDate = new Date(this.newEventEnd)
          let testDate = new Date(date)
          endDate.setHours(0, 0, 0)
          testDate.setHours(0, 0, 0)
          return testDate <= endDate
        }
      }
    },
    allowedDatesEnd() {
      if (!this.newEventStart) {
        return null;
      } else {
        return date => {
          let startDate = new Date(this.newEventStart)
          let testDate = new Date(date)
          testDate.setHours(0, 0, 0)
          startDate.setHours(0, 0, 0)
          return testDate >= startDate
        }
      }
    }
  },
  components: {
    Color: () => import("@/Components/Commons/UiElements/Color"),
    ColorPicker: () => import("@/Components/Commons/Inputs/Color/ColorPicker")
  },
  data() {
    return {
      moment,
      showEditColor: false,
      selectedOpen: this.value,
      deleting: false,
      editing: false,
      event: Object.assign({}, this.selectedEvent),
      newEventStart: null,
      newEventEnd: null,
      cheatKey: null
    }
  },
  methods: {
    close: function () {
      this.editing = false
      this.$emit('close')
    },
    editEvent: function () {
      this.editing = false
      if (this.event.start && this.event.end) {
        if (this.event.start.getDay() == this.event.end.getDay()
            && this.event.start.getMonth() == this.event.end.getMonth()) {
          this.event.timed = true
        } else {
          this.event.timed = false
        }
      } else {
        this.event.timed = false
      }
      this.$emit('update', this.event)
    },
    deleteEvent: function () {
      this.deleting = false
      this.selectedOpen = false
      this.$emit("remove", this.event)
    }
  },
  watch: {
    value(v) {
      if (v) {
        this.event = Object.assign({}, this.selectedEvent)
        this.editing = false
      }
    }
  }
}
</script>

<style scoped>

</style>